import React from 'react';
import MoreOptions from 'src/components/MoreOptions';
import PrivacyDetails from 'src/components/PrivacyDetails';
import { useDependencyManagerContext } from 'src/contexts/dependencyManager';
import { Content } from 'src/styles/styles';
import { Header } from './styles';

const Privacy = () => {
  const { translate } = useDependencyManagerContext();

  return (
    <>
      <Header data-testid="header">
        <h4>{`${translate('menu.privacy', 'Data & privacy')}`}</h4>
        <p className="body">
          {`${translate(
            'privacy.reviewData',
            'Review your data and privacy choices'
          )}`}
        </p>
      </Header>
      <Content>
        <PrivacyDetails />
        <MoreOptions />
      </Content>
    </>
  );
};

export default Privacy;
