export enum FeatureFlagsEnum {
  qualtricsSurvey = 'qualtrics-survey',
  originalFeedbackExperience = 'original-feedback-experience'
}

export enum Stack {
  dev,
  pie,
  stage,
  prod
}
