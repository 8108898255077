import React, { useEffect } from 'react';
import {
  useWebcomponent,
  LookingForSomethingElse
} from '@hpx-core-experiences/react-my-account-commons/dist/index';
import Privacy from 'src/Pages/Privacy';
import 'src/styles/global.scss';
import {
  DataAndPrivacyScreenDisplayed,
  publishEvent,
  screenName,
  screenPath
} from 'src/utils/analytics';
import { Container } from './styles';
import useFeatureFlags from 'src/hooks/useFeatureFlags';
import { FeatureFlagsEnum } from 'src/utils/enums';
import { getQualtricsSurveyUrl } from 'src/utils/urls';
import {
  DependencyManagerProvider,
  useDependencyManagerContext
} from 'src/contexts/dependencyManager';
import * as T from './types';

const AppComponent: React.FC<T.AppPropsType> = () => {
  const { translate } = useDependencyManagerContext();

  const {
    featureFlagValue: qualtricsSurvey,
    isLoading: isQualtricsSurveyLoading
  } = useFeatureFlags(FeatureFlagsEnum.qualtricsSurvey);

  const showQualtricsSurvey = qualtricsSurvey && !isQualtricsSurveyLoading;

  const {
    featureFlagValue: originalFeedbackExperience,
    isLoading: isOriginalFeedbackExperienceLoading
  } = useFeatureFlags(FeatureFlagsEnum.originalFeedbackExperience);

  const showOriginalFeedbackExperience =
    originalFeedbackExperience && !isOriginalFeedbackExperienceLoading;

  useWebcomponent({
    url: showQualtricsSurvey ? getQualtricsSurveyUrl() : '',
    scriptType: 'text/javascript',
    scriptId: 'qualtrics-survey',
    target: 'head'
  });

  useEffect(() => {
    publishEvent(DataAndPrivacyScreenDisplayed);
  }, []);

  return (
    <Container data-testid="privacy-page">
      <Privacy />
      {showOriginalFeedbackExperience && (
        <LookingForSomethingElse
          analytics={{
            screenName: screenName,
            screenPath: screenPath
          }}
          translate={translate}
        />
      )}
    </Container>
  );
};

// This component wraps your main component with the DependencyManagerProvider
// This provider is responsible to manage global dependencies to your component
const App: React.FC<T.AppPropsType> = (props) => {
  return (
    <DependencyManagerProvider>
      <AppComponent {...props} />
    </DependencyManagerProvider>
  );
};

export default App;
